<template>
  <div class="download-box-button">
    <div class="hover-wrap" @click="downLoad">
      <span>{{ download }}</span>
      <img src="../assets/images/main/download.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadBoxButton',
  props: {
    download: {
      type: String,
      required: true,
    },
  },
  methods: {
    downLoad() {
      if (this.$store.state.language === 'zh') {
        window.open(
          'https://chrome.google.com/webstore/detail/dfox/mfgccjchihfkkindfppnaooecgfneiii?hl=zh-CN',
        )
      } else {
        window.open(
          'https://chrome.google.com/webstore/detail/dfox/mfgccjchihfkkindfppnaooecgfneiii?hl=EN',
        )
      }
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and(min-width:767px) {
  .download-box-button {
    height: 0.6rem;
    margin-top: 0.54rem;
    .hover-wrap {
      cursor: pointer;
      margin-top: 0.06rem;
      width: 2.88rem;
      height: 0.58rem;
      background: #2980FE;
      border-radius: 0.43rem;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 0.2rem;
        font-family: HelveticaNeue, HelveticaNeue-Regular;
        font-weight: 400;
        text-align: left;
        color: #fff;
      }
      img {
        margin-left: 0.15rem;
        width: 0.22rem;
        height: 0.22rem;
      }
    }
    .hover-wrap:hover{
      margin-top: 0;
    }
  }
}
@media screen and(max-width:767px) {
  .download-box-button {
    width: 4.3rem;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 0.54rem;
    .hover-wrap {
      width: 4.3rem;
      height: 1rem;
      // background: #7051e1;
      background: #2980FE;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 0.3rem;
        font-family: HelveticaNeue, HelveticaNeue-Regular;
        font-weight: 500;
        text-align: left;
        color: #fff;
      }
      img {
        margin-left: 0.15rem;
        width: 0.26rem;
        height: 0.26rem;
      }
    }
  }
}
</style>
