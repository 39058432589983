const iconGrey = require('../../assets/images/main/chrome-grey.png')
const iconDesktop = require('../../assets/images/header/desktop.png')
const installTutorial = '挿件安裝教程'
const installTutorialUrl =
  'https://help.tokenpocket.pro/en/extension-wallet/faq/installation-tutorial'

export default {
  language: '繁體',
  extension: '挿件錢包',
  download: '立即下載',
  dfoxTitle: '區塊鏈入口探索 Web3 世界',
  dfoxDesc: '區塊鏈入口，探索去中心化應用',
  downloadFor: '立即下載',
  upgradeWarn: 'dfox 挿件錢包宣佈更名，品牌名稱正式陞級為 TokenPocket 挿件錢包',
  installTutorial: installTutorial,
  installTutorialUrl: installTutorialUrl,
  supportText: '現已支持Chrome、Brave瀏覽器（Firefox及Opera正在支持中)',
  mobileDropDownList: [
    {
      title: installTutorial,
      url: installTutorialUrl,
    },
    {
      title: '語言',
      lang: true,
      children: [
        { langText: '简体', lang: 'zh' },
        { langText: '繁體', lang: 'tc' },
        { langText: 'English', lang: 'en' },
      ],
    },
  ],
  btnList: [
    {
      icon: require('../../assets/images/header/chrome.png'),
      iconGrey: iconGrey,
      btnTextTop: '下載',
      btnText: 'Chrome',
      installTutorial: 'Install Tutorial',
      installTutorialUrl: installTutorialUrl,
      url: 'https://chrome.google.com/webstore/detail/dfox/mfgccjchihfkkindfppnaooecgfneiii?hl=EN',
    },
    {
      icon: iconDesktop,
      iconGrey: iconDesktop,
      btnTextTop: 'Download to',
      btnText: '本地下載',
      installTutorial: '挿件安裝教程',
      installTutorialUrl: installTutorialUrl,
      url: 'https://extension.tokenpocket.pro/tp-chrome.zip',
    },
  ],
  dfoxImg: require('../../assets/images/header/dfox-en.png'),
  dappsDefi: {
    title: 'Dapp生態入口',
    desc: '探索DeFi，GameFi等，快速玩轉DApp經濟',
  },
  multiChain: {
    title: '多鏈錢包，簡單易用',
    desc: '一個錢包，管理多鏈資產',
  },
  installDofx: '添加TokenPocket挿件至瀏覽器',
  joinDfox: '加入TokenPocket社區',
  linkList: [
    {
      text: '隱私政策',
      url: 'https://help.tokenpocket.pro/en/extension-wallet/privacy-policy',
    },
    {
      text: '服務協定',
      url: 'https://help.tokenpocket.pro/en/extension-wallet/term-of-use',
    },
  ],
}
