// import Store from 'store'

// 图片CDN的前缀
export const IMG_CDN = 'https://cdn.sixpencer.com/DFox/images/'
// ios app ID
export const APPSTORE_ID = '414478124'
// ios app is coming
export const IOS_ISCOMING = false

// 判断安卓
export function isAndroid() {
  var u = navigator.userAgent
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
    if (window.ShowFitness !== undefined) return true
  }
  return false
}
// 判断设备为 ios
export function isIos() {
  var u = navigator.userAgent
  if (u.indexOf('iPhone') > -1 || u.indexOf('iOS') > -1) {
    return true
  }
  return false
}

export function checkClinetModel() {
  //获取请求头中的userAgent
  var u = navigator.userAgent
  //是否是android浏览器
  var isAndroid = u.indexOf('Android') > -1
  //是否是ios浏览器
  var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  var msg = ''
  if (isAndroid) {
    msg = 'android'
  } else if (isIos) {
    msg = 'ios'
  } else {
    msg = 'pc'
  }
  return msg
}

export function isPC() {
  var userAgentInfo = navigator.userAgent
  var Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ]
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

// export function getImageUrlByLang(imgBaseUrl: string) {
//     if (Store.currentLang === '简体中文') {
//         return imgBaseUrl
//     } else {
//         return imgBaseUrl.split('.png')[0] + '-en.png' //+ imgBaseUrl.split('.')[1]
//     }
// }

// export function handleResize() {
//     window.onresize = debounce(() => {
//         console.log('window resize')
//         setTimeout(() => {
//             const client = checkClinetModel()
//             localStorage.setItem('client', client)
//             Store.setClient(client)
//         }, 200)
//     }, 100, true)
// }

export function debounce(func, threshold, execAsap) {
  var timeout
  return function debounced() {
    var obj = this,
      args = arguments
    function delayed() {
      if (!execAsap) func.apply(obj, args)
      timeout = null
    }
    if (timeout) clearTimeout(timeout)
    else if (execAsap) func.apply(obj, args)
    timeout = setTimeout(delayed, threshold || 100)
  }
}

// 下载apk
export function downloadAPK() {
  const dom = document.createElement('a')
  // dom.href = `https://sixpencer.com/release.apk`
  dom.href = `https://cdn.sixpencer.com/DFox/apk/release.apk`
  dom.target = `_blank`
  dom.click()
}

// google play
export function openGooglePlay() {
  const dom = document.createElement('a')
  dom.href = `https://play.google.com/store/apps/details?id=com.sixpencer.simplework`
  dom.target = `_blank`
  dom.click()
}

// ios系统打开appstore商店 网页或者iphone手机商店
// export function openAppStore() {
//     if (IOS_ISCOMING) {
//         return false
//     }
//     const dom = document.createElement('a')
//     dom.href = `https://apps.apple.com/cn/app/dfox-wallet-defi-portfolio/id1529717509`
//     if (Store.currentLang === "简体中文"){
//         dom.href = `https://apps.apple.com/cn/app/dfox-wallet-defi-portfolio/id1529717509`
//     }else {
//         dom.href = `https://apps.apple.com/us/app/dfox-wallet-defi-portfolio/id1529717509`
//     }

//     //`https://apps.apple.com/us/app/wechat/id${APPSTORE_ID}`
//     dom.target = `_blank`
//     dom.click()
// }

// ios系统打开testflight商店 网页或者iphone手机商店
export function openTestFlight() {
  if (IOS_ISCOMING) {
    return false
  }
  const dom = document.createElement('a')
  dom.href = `https://testflight.apple.com/join/debkGbVf`
  dom.target = `_blank`
  dom.click()
}

// 回到顶部
export function goTop() {
  document.documentElement.scrollTop = 0
  scrollTo(0, 0)
}

// 根据不同环境 得到qrcode
export function getQrcodeUrl() {
  if (!window) {
    return 'https://cdn.sixpencer.com/DFox/images/prod.png'
  } else {
    const host = window.location.host
    if (host.includes('localhost') || host.includes('test')) {
      return 'https://cdn.sixpencer.com/DFox/images/test.png'
    } else {
      return 'https://cdn.sixpencer.com/DFox/images/prod.png'
    }
  }
}

// 获取cdn图片
export function image(surfix) {
  // https://cdn.sixpencer.com/DFox/images/
  const prefix = IMG_CDN
  return prefix + surfix
}

// 更多defi
export function gotoDefis() {
  const dom = document.createElement('a')
  // dom.href = `https://sixpencer.com/release.apk`
  dom.href = `https://docs.dfox.cc/tutorials-and-faqs/defi-projects-supported`
  dom.target = `_blank`
  dom.click()
}
