<template>
  <div class="DownloadBox" :class="{ pl: pl }">
    <span class="title">{{ boxObj.title }}</span>
    <span class="desc">{{ boxObj.desc }}</span>
    <div class="coins" v-if="coins">
      <div class="img-content" v-for="(item, index) in chainList" :key="index">
        <img :src="item.icon" />
      </div>
    </div>
    <DownloadButton :download="download" />
  </div>
</template>

<script>
import DownloadButton from './DownloadButton'

export default {
  name: 'DownloadBox',
  components: {
    DownloadButton,
  },
  props: {
    coins: {
      type: Boolean,
    },
    boxObj: {
      type: Object,
      required: true,
    },
    download: {
      type: String,
      required: true,
    },
    pl: {
      type: Boolean,
    },
  },
  data() {
    return {
      chainList: [
        {
          icon: require('../assets/images/main/button-box/01.png'),
        },
        {
          icon: require('../assets/images/main/button-box/02.png'),
        },
        {
          icon: require('../assets/images/main/button-box/04.png'),
        },
        {
          icon: require('../assets/images/main/button-box/05.png'),
        },
        {
          icon: require('../assets/images/main/button-box/06.png'),
        },
        {
          icon: require('../assets/images/main/button-box/07.png'),
        },
        {
          icon: require('../assets/images/main/button-box/08.png'),
        },
        {
          icon: require('../assets/images/main/button-box/09.png'),
        },
        {
          icon: require('../assets/images/main/button-box/10.png'),
        },
        {
          icon: require('../assets/images/main/button-box/11.png'),
        },
        {
          icon: require('../assets/images/main/button-box/12.png'),
        },
        {
          icon: require('../assets/images/main/button-box/13.png'),
        },
        {
          icon: require('../assets/images/main/button-box/14.png'),
        },
        {
          icon: require('../assets/images/main/button-box/15.png'),
        },
        {
          icon: require('../assets/images/main/button-box/16.png'),
        },
        {
          icon: require('../assets/images/main/button-box/03.png'),
        },
        {
          icon: require('../assets/images/main/button-box/17.png'),
        },
        {
          icon: require('../assets/images/main/button-box/18.png'),
        },
        {
          icon: require('../assets/images/main/button-box/19.png'),
        },
        {
          icon: require('../assets/images/main/button-box/20.png'),
        },
        {
          icon: require('../assets/images/main/button-box/21.png'),
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
@media screen and(min-width:767px) {
  .DownloadBox {
    width: 6rem;
    display: flex;
    flex-direction: column;
    &.pl {
      padding: 0.9rem;
    }
    .title {
      font-size: 0.36rem;
      font-family: HelveticaNeue, HelveticaNeue-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
    }
    .desc {
      margin-top: 0.3rem;
      font-size: 0.22rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
    }
    .coins {
      margin-top: 0.3rem;
      display: flex;
      flex-wrap: wrap;
      .img-content {
        width: 0.32rem;
        height: 0.32rem;
        background: #f5f9ff;
        border-radius: 50%;
        display: flex;
        img {
          border-radius: 50%;
          width: 0.32rem;
          height: 0.32rem;
        }
        margin: 0 0.15rem 0.15rem 0;
        // &:nth-of-type(n + 2) {
        //   margin-right: 0.1rem;
        //   margin: 0 0.2rem 0.2rem 0;
        // }
      }
    }
  }
}

@media screen and(max-width:767px) {
  .DownloadBox {
    width: 4.9rem;
    display: flex;
    flex-direction: column;
    .title {
      margin-top: 0.7rem;
      font-size: 0.54rem;
      font-family: HelveticaNeue, HelveticaNeue-Regular;
      font-weight: 700;
      text-align: center;
      color: #333333;
    }
    .desc {
      margin-top: 0.3rem;
      font-size: 0.26rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #999999;
    }
    .coins {
      margin-top: 0.3rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .img-content {
        width: 0.66rem;
        height: 0.66rem;
        background: #f5f9ff;
        border-radius: 50%;
        display: flex;
        margin: 0.15rem 0;
        img {
          border-radius: 50%;
          width: 0.66rem;
          height: 0.66rem;
        }
        &:nth-of-type(n + 2) {
          margin-left: 0.1rem;
        }
      }
    }
  }
}
</style>
