<template>
  <div class="network-list">
    <div class="network">{{ network }}</div>
    <div class="line"></div>
    <div class="network-list-content">
      <div class="item" v-for="(item, index) in iconList" :key="index">
        <img :src="item.url" />
        <span>{{ item.rpcName }}</span>
        <img src="../assets/images/main/selected.png" />
      </div>
    </div>
    <div class="line"></div>
    <div class="custom-rpc">{{ customRPC }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      iconList: [
        {
          url: require('../assets/images/main/network/01.png'),
          rpcName: 'Ethereum Mainnet',
        },
        {
          url: require('../assets/images/main/network/02.png'),
          rpcName: 'BNB Chain',
        },
        {
          url: require('../assets/images/main/network/05.png'),
          rpcName: 'Polygon(Matic)',
        },
        {
          url: require('../assets/images/main/network/06.png'),
          rpcName: 'Avalanche C-Chain',
        },
        {
          url: require('../assets/images/main/network/09.png'),
          rpcName: 'Fantom',
        },
        {
          url: require('../assets/images/main/network/03.png'),
          rpcName: 'HECO Chain',
        },
        {
          url: require('../assets/images/main/network/11.png'),
          rpcName: 'Arbitrum',
        },
        {
          url: require('../assets/images/main/network/04.png'),
          rpcName: 'OKXChain',
        },
        {
          url: require('../assets/images/main/network/07.png'),
          rpcName: 'KCC Mainnet',
        },
        {
          url: require('../assets/images/main/network/08.png'),
          rpcName: 'Harmony',
        },
        {
          url: require('../assets/images/main/network/10.png'),
          rpcName: 'Klaytn',
        },
        {
          url: require('../assets/images/main/network/12.png'),
          rpcName: 'Aurora',
        },
        {
          url: require('../assets/images/main/network/13.png'),
          rpcName: 'Optimistic Ethereum',
        },
        {
          url: require('../assets/images/main/network/14.png'),
          rpcName: 'Hoo Smart Chain',
        },
        {
          url: require('../assets/images/main/network/15.png'),
          rpcName: 'Moonbeam',
        },
        {
          url: require('../assets/images/main/network/16.png'),
          rpcName: 'Gonisis Chain(xDai)',
        },
        // {
        //   url: require('../assets/images/main/network/17.png'),
        //   rpcName: 'Gonisis Chain(xDai)',
        // },
        // {
        //   url: require('../assets/images/main/network/18.png'),
        //   rpcName: 'Gonisis Chain(xDai)',
        // },
        // {
        //   url: require('../assets/images/main/network/19.png'),
        //   rpcName: 'Gonisis Chain(xDai)',
        // },
        // {
        //   url: require('../assets/images/main/network/20.png'),
        //   rpcName: 'Gonisis Chain(xDai)',
        // },
        // {
        //   url: require('../assets/images/main/network/21.png'),
        //   rpcName: 'Gonisis Chain(xDai)',
        // },
      ],
    }
  },
  computed: {
    ...mapGetters(['language']),
    network() {
      return this.language === 'zh' ? '网络' : 'Networks'
    },
    customRPC() {
      return this.language === 'zh' ? '自定义 RPC' : 'Custom RPC'
    },
  },
}
</script>

<style lang="less">
@media screen and(min-width:767px) {
  .network-list {
    position: absolute;
    left: 1.3rem;
    top: 1.05rem;
    width: 2.4rem;
    height: 4.9rem;
    background: #ffffff;
    box-shadow: 0 0.02rem 0.4rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.08rem;
    .network {
      height: 0.45rem;
      line-height: 0.45rem;
      margin-left: 0.15rem;
      font-size: 0.13rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
    }
    .line {
      width: 100%;
      height: 0.02rem;
      background: #eeeeee;
    }
    .network-list-content {
      padding: 0 0.15rem;
      max-height: 4rem;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      overflow-y: auto;
      .item {
        margin-top: 0.1rem;
        width: 2.11rem;
        height: 0.45rem;
        padding: 0 0.1rem;
        background: #fff;
        // border: 0.01rem solid #7051e1;
        border: 0.01rem solid #2980fe;
        border-radius: 0.06rem;
        display: flex;
        align-items: center;
        color: #333;
        &:nth-of-type(n + 2) {
          margin-top: 0.06rem;
          border: 0.01rem solid #eee;
          :nth-child(3) {
            display: none;
          }
        }
        &:nth-child(1) {
          // background: #7051e1;
          background: #2980fe;
          color: #fff;
        }
        &:nth-child(3) {
          // background: #f7f5ff;
          background: #f5f9ff;
        }
        :nth-child(1) {
          width: 0.24rem;
          height: 0.24rem;
        }
        :nth-child(2) {
          margin-left: 0.08rem;
          font-size: 0.13rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
        }
        :nth-child(3) {
          margin-left: 0.2rem;
          width: 0.16rem;
          height: 0.16rem;
        }
      }
    }
    .custom-rpc {
      height: 0.45rem;
      line-height: 0.45rem;
      font-size: 0.13rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #333;
    }
  }
}
@media screen and(max-width:767px) {
  .network-list {
    position: absolute;
    left: 1rem;
    top: 1.05rem;
    width: 2.4rem;
    height: 4.9rem;
    background: #ffffff;
    box-shadow: 0 0.02rem 0.4rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.08rem;
    .network {
      height: 0.45rem;
      line-height: 0.45rem;
      margin-left: 0.15rem;
      font-size: 0.13rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
    }
    .line {
      width: 100%;
      height: 0.02rem;
      background: #eeeeee;
    }
    .network-list-content {
      padding: 0 0.15rem;
      max-height: 4rem;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      overflow-y: auto;
      .item {
        margin-top: 0.1rem;
        width: 2.11rem;
        height: 0.45rem;
        padding: 0 0.1rem;
        background: #fff;
        // border: 0.01rem solid rgb(112, 81, 225);
        border: 0.01rem solid #2980fe;
        border-radius: 0.06rem;
        display: flex;
        align-items: center;
        color: #333;
        &:nth-of-type(n + 2) {
          margin-top: 0.06rem;
          border: 0.01rem solid #eee;
          :nth-child(3) {
            display: none;
          }
        }
        &:nth-child(1) {
          // background: #7051e1;
          background: #2980fe;
          color: #fff;
        }
        &:nth-child(3) {
          background: #f7f5ff;
        }
        :nth-child(1) {
          width: 0.24rem;
          height: 0.24rem;
        }
        :nth-child(2) {
          margin-left: 0.08rem;
          font-size: 0.13rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
        }
        :nth-child(3) {
          margin-left: 0.2rem;
          width: 0.16rem;
          height: 0.16rem;
        }
      }
    }
    .custom-rpc {
      height: 0.45rem;
      line-height: 0.45rem;
      font-size: 0.13rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #333;
    }
  }
}
</style>
