<template>
  <div class="header-nav-bar">
    <div class="top">
      <div class="top-left">
        <img src="../assets/images/header/logo.png" />
        <span class="top-text">{{ messages.extension }}</span>
      </div>
      <div class="top-right">
        <span class="top-text" @click="goInstallTutorial">{{
          messages.installTutorial
        }}</span>
        <div class="change-language top-text">
          {{ messages.language }}
          <div class="language-box">
            <div
              class="lang-text"
              v-for="(langObj, index) in languageList"
              :key="index"
              @click="changeLang(langObj.lang)"
            >
              {{ langObj.langText }}
            </div>
          </div>
        </div>
        <span @click="goChrome" class="top-text top-text-box">{{
          messages.download
        }}</span>
      </div>
    </div>
    <div class="content">
      <div class="content-left">
        <span class="title-1" v-if="language !== 'en'">
          {{ messages.dfoxTitle.slice(0, 5) }} <br />
          {{ messages.dfoxTitle.slice(5) }}
        </span>
        <span class="title-1" v-else>
          {{ messages.dfoxTitle }}
        </span>
        <!-- <span class="title-2">{{ messages.dfoxDesc }}</span> -->
        <div class="zh-placeholder" v-if="language !== 'en'"></div>
        <div class="download-for">
          <span class="download-for-text">{{ messages.downloadFor }}</span>
          <div class="btn-tutorial">
            <div
              class="btn-tutorial-wrap"
              v-for="(item, index) in messages.btnList"
              :key="index"
            >
              <div class="btn-wrap">
                <div
                  class="btn-box"
                  :class="{ active: index === grayIndex }"
                  @click="openUrl(item.url, index)"
                >
                  <img
                    v-if="index === grayIndex"
                    src="../assets/images/header/chrome-grey.png"
                  />
                  <img v-else :src="item.icon" />
                  <div class="text-wrap">
                    <span
                      v-if="
                        (index !== 1 &&
                          (language === 'zh' || language === 'tc')) ||
                        language === 'en'
                      "
                      :class="{ grey: index === grayIndex }"
                    >
                      {{ item.btnTextTop }}
                    </span>
                    <span
                      class="bold-text"
                      :class="{ grey: index === grayIndex }"
                      >{{ item.btnText }}</span
                    >
                  </div>
                </div>
              </div>
              <!-- 此处是修改需求不在这里显示教程入口 -->
              <!-- <div
                class="tutorial-box"
                v-if="index === 10"
                @click="openUrl(item.installTutorialUrl)"
              >
                <span>{{ item.installTutorial }}</span>
                <div class="arrow"></div>
              </div> -->
            </div>
          </div>
          <div class="title-2 support-text">
            {{ messages.supportText }}
          </div>
        </div>
      </div>
      <div class="content-right">
        <img :src="messages.dfoxImg" />
        <img src="../assets/images/header/dfox-glass-1.png" />
        <img src="../assets/images/header/dfox-glass-2.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      languageList: [
        { langText: '简体', lang: 'zh' },
        { langText: '繁體', lang: 'tc' },
        { langText: 'English', lang: 'en' },
      ],
      grayIndex: 10, // 需要第几个按钮置灰，如果超出则全部不置灰
    }
  },
  computed: {
    ...mapGetters(['language']),
  },
  methods: {
    changeLang(lang) {
      this.$store.commit('changeLanguage', lang)
    },
    openUrl(url, index) {
      if (index === 1) {
        window.open(url, '_self')
      } else {
        window.open(url)
      }
    },
    goChrome() {
      if (this.language === 'zh') {
        window.open(
          'https://chrome.google.com/webstore/detail/dfox/mfgccjchihfkkindfppnaooecgfneiii?hl=zh-CN'
        )
      } else {
        window.open(
          'https://chrome.google.com/webstore/detail/dfox/mfgccjchihfkkindfppnaooecgfneiii?hl=EN'
        )
      }
    },
    goInstallTutorial() {
      window.open(this.messages.installTutorialUrl)
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and(min-width:767px) {
  .header-nav-bar {
    width: 100%;
    height: 8rem;
    background: url('../assets/images/header/bg.png') no-repeat;
    background-size: 100% 8rem;
    .top {
      width: 10.5rem;
      margin: 0 auto;
      padding-top: 0.53rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .top-text {
        font-size: 0.16rem;
        font-family: PingFangSC, PingFangSC-Regular;
        color: #ffffff;
        cursor: pointer;
      }
      .top-left {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 1.78rem;
          height: 0.26rem;
        }
        :nth-child(2) {
          font-size: 0.2rem;
          // font-family: PingFangSC, PingFangSC-Medium;
          color: #ffffff;
          margin-left: 0.13rem;
          cursor: text;
        }
      }
      .top-right {
        width: 3.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .change-language {
          width: 0.7rem;
          position: relative;
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          cursor: pointer;
          .language-box {
            position: absolute;
            z-index: 1110;
            left: -0.375rem;
            width: 1.5rem;
            background: #fff;
            border-radius: 0.08rem;
            display: none;
            .lang-text {
              padding: 0 0.4rem;
              color: #000;
              font-size: 0.16rem;
              margin-left: 0;
              border-radius: 0.08rem;
              text-align: center;
              border-bottom: 0.01rem solid #f7f5ff;
              &:hover {
                color: #7051e1;
                background-color: #eee;
              }
            }
          }
        }
        .change-language:hover {
          .language-box {
            display: flex;
            flex-direction: column;
          }
        }
        .top-text-box {
          width: 1.54rem;
          height: 0.4rem;
          line-height: 0.4rem;
          border: 0.01rem solid #ffffff;
          border-radius: 0.21rem;
          text-align: center;
        }
      }
    }
    .content {
      width: 15rem;
      margin: 0 auto;
      margin-top: 0.5rem;
      display: flex;
      .content-left {
        width: 5.8rem;
        margin-left: 2.7rem;
        margin-top: 0.4rem;
        display: flex;
        flex-direction: column;
        .title-1 {
          font-size: 0.48rem;
          font-family: HelveticaNeue, HelveticaNeue-Regular;
          font-weight: 700;
          text-align: left;
          color: #ffffff;
        }
        .title-2 {
          margin-top: 0.5rem;
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
        }
        .zh-placeholder {
          margin-top: 0.6rem;
          width: 0.3rem;
          height: 0.04rem;
          background: #ffffff;
          border-radius: 0.02rem;
        }
        .download-for {
          margin-top: 0.6rem;
          display: flex;
          flex-direction: column;
          .download-for-text {
            font-size: 0.22rem;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #ffffff;
          }
          .btn-tutorial {
            margin-top: 0.3rem;
            display: flex;
            .btn-tutorial-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              // 选择第二个元素之后的所有元素
              &:nth-of-type(n + 2) {
                margin-left: 0.2rem;
              }
              .btn-wrap {
                height: 0.66rem;
                .btn-box {
                  margin-top: 0.06rem;
                  background: url('../assets/images/header/btn-bg.png')
                    no-repeat;
                  background-size: 100% 100%;
                  width: 1.55rem;
                  height: 0.58rem;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                  cursor: pointer;
                  &.active {
                    pointer-events: none;
                    background: url('../assets/images/header/btn-bg-grey.png')
                      no-repeat;
                    background-size: 100% 100%;
                  }
                  img {
                    width: 0.32rem;
                    height: 0.32rem;
                  }
                  .text-wrap {
                    display: flex;
                    flex-direction: column;
                    span {
                      font-size: 0.12rem;
                      font-family: PingFangSC, PingFangSC-Regular;
                      font-weight: 400;
                      text-align: left;
                      color: #ffffff;
                    }
                    .bold-text {
                      font-size: 0.18rem;
                      font-family: PingFangSC, PingFangSC-Medium;
                      font-weight: 500;
                    }
                    .grey {
                      color: #463069;
                    }
                  }
                }
                .btn-box:hover {
                  margin-top: 0;
                  background: url('../assets/images/header/btn-bg-hover.png')
                    no-repeat;
                  background-size: 100%;
                  img {
                    margin-top: 0.04rem;
                  }
                  .text-wrap {
                    margin-top: 0.04rem;
                  }
                }
              }
              .tutorial-box {
                margin-top: 0.2rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                span {
                  font-size: 0.12rem;
                  font-family: PingFangSC, PingFangSC-Medium;
                  font-weight: 500;
                  color: #ffffff;
                }
                .arrow {
                  margin-left: 0.05rem;
                  width: 0.06rem;
                  height: 0.1rem;
                  background: url('../assets/images/header/arrow-white.png')
                    no-repeat;
                  background-size: 100% 100%;
                }
              }
              .tutorial-box:hover {
                span {
                  color: #0debff;
                }
                .arrow {
                  background: url('../assets/images/header/arrow-blue.png')
                    no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
          }
        }
        .support-text {
          margin-top: 0.3rem;
          opacity: 0.85;
          font-size: 0.12rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
        }
      }
      .content-right {
        width: 10rem;
        margin-left: 2rem;
        position: relative;
        @keyframes move {
          0% {
            top: 0;
          }
          20% {
            top: -0.7rem;
          }
          40% {
            top: 0;
          }
          60% {
            top: -0.6rem;
          }
          100% {
            top: 0;
          }
        }
        :nth-child(1) {
          position: absolute;
          z-index: 1001;
          width: 3.58rem;
          height: 6.45rem;
          animation: move 0.8s ease-in-out 1.6s;
        }
        :nth-child(2) {
          position: absolute;
          z-index: 1000;
          width: 2.52rem;
          height: 5.48rem;
          left: 2rem;
          animation: move 0.8s ease-in-out 1.4s;
        }
        :nth-child(3) {
          position: absolute;
          z-index: 999;
          width: 1.99rem;
          height: 4.27rem;
          left: 4rem;
          animation: move 0.8s ease-in-out 1.2s;
        }
      }
    }
  }
}
</style>
