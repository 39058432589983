<template>
  <div class="footer-bottom">
    <template v-if="isPc">
      <div class="footer-bottom-content">
        <div class="left">
          <img src="../assets/images/header/logo.png" />
          <span class="text">{{ copyrightInformation }}</span>
        </div>
        <div class="center">
          <div class="text title">{{ messages.joinDfox }}</div>
          <div class="links">
            <a
              :href="item.url"
              v-for="(item, index) in messages.linkList"
              :key="index"
              :target="item.url ? '_blank' : '_self'"
            >
              <span class="text">{{ item.text }}</span>
            </a>
          </div>
        </div>
        <div class="right">
          <a
            v-for="(item, index) in linkIconList"
            :class="[item.class]"
            :href="item.url ? item.url : 'javascript:void(0)'"
            :target="item.url ? '_blank' : '_self'"
            :key="index"
          ></a>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="footer-bottom-content">
        <div class="left">
          <img src="../assets/images/header/logo.png" />
        </div>
        <div class="center">
          <div class="text title">{{ messages.joinDfox }}</div>
          <div class="links">
            <a
              :href="item.url"
              v-for="(item, index) in messages.linkList"
              :key="index"
            >
              <span class="text">{{ item.text }}</span>
            </a>
          </div>
        </div>
        <div class="right">
          <!-- :target="item.url ? '_blank' : '_self'" -->
          <a
            :href="item.url ? item.url : 'javascript:void(0)'"
            v-for="(item, index) in linkIconList"
            :class="[item.class]"
            target="_self"
            :key="index"
          ></a>
        </div>
        <div class="line"></div>
        <div class="text bottom-text">{{ copyrightInformation }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { checkClinetModel } from '../utils'
export default {
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      linkIconList: [
        {
          class: 'icon-1',
          // url: 'https://twitter.com/dfoxapp',
          url: 'https://twitter.com/TokenPocket_TP',
        },
        // {
        //   class: 'icon-2',
        //   url: 'https://t.me/tokenpocket_cn',
        // },
        // {
        //   class: 'icon-3',
        //   url: 'https://www.facebook.com/TokenPocket',
        // },
        // {
        //   class: 'icon-4',
        //   url: 'https://www.youtube.com/',
        // },
        {
          class: 'icon-6',
          url: 'mailto:service@tokenpocket.pro',
        },
        {
          class: 'icon-5',
          url: 'https://fans.tokenpocket.pro/',
        },
      ],
      copyrightInformation: 'Copyright © 2022 TokenPocket',
    }
  },
  computed: {
    isPc() {
      if (checkClinetModel() === 'pc') {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="less" scoped>
//定义一个循环  初始化i=0,循环体内i+1,条件i<10内 满足条件
.loop(@i) when(@i<=6) {
  //递归调用 达到循环的目的
  .icon-@{i} {
    background: url('../assets/images/footer/icon-off-@{i}.png') no-repeat;
    background-size: contain;
    &:hover {
      background: url('../assets/images/footer/icon-on-@{i}.png') no-repeat;
      background-size: contain;
    }
  }
  .loop((@i+1)); //执行某个需要循环的方法
}
//初始化开始循环 不初始化 不会开始循环
.loop(1);
.footer-bottom {
  // background-color: #1a013a;
  background-color: #2761E7;
  .footer-bottom-content {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text {
      font-size: 0.12rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
    }
    .left {
      display: flex;
      flex-direction: column;
      img {
        width: 1.78rem;
        height: 0.26rem;
      }
      span {
        margin-top: 0.15rem;
        font-size: 0.12rem;
      }
    }
    .center {
      .title {
        font-size: 0.18rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
      }
      .links {
        margin-top: 0.1rem;
        display: flex;
        a {
          display: flex;
          span {
            font-size: 0.14rem;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
              // color: #7b59f5;
              color: #00FFF0;
            }
          }
          &:nth-child(2) {
            margin-left: 0.4rem;
          }
        }
      }
    }
    .right {
      display: flex;
      a {
        width: 0.4rem;
        height: 0.4rem;
      }
      :nth-of-type(n + 2) {
        margin-left: 0.16rem;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .footer-bottom {
    .footer-bottom-content {
      width: 10rem;
      height: 2.18rem;
    }
  }
}
@media screen and (max-width: 767px) {
  .footer-bottom {
    .footer-bottom-content {
      // width: 5rem;
      padding: 0 0.5rem;
      height: 7rem;
      flex-direction: column;
      justify-content: space-evenly;
      .left {
        align-items: center;
        img {
          // width: 2.19rem;
          // height: 0.32rem;
           width: 2.628rem;
          height: 0.384rem;
        }
      }
      .center {
        .title {
          text-align: center;
          font-size: 0.22rem;
        }
        .links {
          a {
            margin-top: 0.4rem;
            span {
              font-size: 0.28rem;
            }
          }
        }
      }
      .right {
        a {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
      .line {
        width: 100%;
        height: 0.01rem;
        opacity: 0.14;
        background: #ffffff;
      }
      .bottom-text {
        font-size: 0.18rem;
        opacity: 0.35;
      }
    }
  }
}
</style>
