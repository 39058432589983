<template>
  <div class="main-content">
    <!-- 顶部 -->
    <div class="content content-header">
      <img src="../assets/images/main/dfox-big.png" />
      <DownloadBox
        :boxObj="messages.dappsDefi"
        :download="messages.download"
        :coins="false"
        :pl="true"
      />
    </div>
    <!-- 中部 -->
    <div class="content content-mid">
      <!-- 判断是pc还是移动 -->
      <template v-if="isPc">
        <DownloadBox
          :boxObj="messages.multiChain"
          :download="messages.download"
          :coins="true"
        />
        <div class="dfox-view">
          <NetworkList />
        </div>
      </template>
      <template v-else>
        <div class="dfox-view">
          <NetworkList />
        </div>
        <DownloadBox
          :boxObj="messages.multiChain"
          :download="messages.download"
          :coins="true"
        />
      </template>
    </div>
    <!-- 底部 -->
    <div class="content content-footer">
      <div class="title">{{ messages.installDofx }}</div>
      <div class="dfox-button-wrap">
        <div
          class="btn-box-content"
          v-for="(btn, index) in messages.btnList"
          :key="index"
        >
          <div class="btn-box-wrap">
            <div
              class="btn-box"
              :class="{ active: index === grayIndex }"
              @click="openUrl(btn.url, index)"
            >
              <img
                v-if="index === grayIndex"
                src="../assets/images/header/chrome-grey.png"
              />
              <img v-else :src="btn.iconGrey" />
              <div class="text-wrap">
                <span
                  v-if="
                    (index !== 1 && (language === 'zh' || language === 'tc')) ||
                    language === 'en'
                  "
                  :class="{ grey: index === grayIndex }"
                >
                  {{ btn.btnTextTop }}
                </span>
                <span
                  class="bold-text"
                  :class="{ grey: index === grayIndex }"
                  >{{ btn.btnText }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="tutorial-box"
            v-if="index === 1"
            @click="openUrl(btn.installTutorialUrl)"
          >
            <span>{{ btn.installTutorial }}</span>
            <div class="arrow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkClinetModel } from '../utils'
import DownloadBox from './DownloadBox.vue'
import NetworkList from './NetworkList.vue'
export default {
  components: {
    DownloadBox,
    NetworkList,
  },
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      grayIndex: 10, // 需要第几个按钮置灰，如果超出则全部不置灰
    }
  },
  computed: {
    ...mapGetters(['language']),
    isPc() {
      if (checkClinetModel() === 'pc') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    openUrl(url, index) {
      console.log(url)
      if (index === 1) {
        window.open(url, '_self')
      } else {
        window.open(url)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and(min-width:767px) {
  .main-content {
    background: url('../assets/images/main/line-bar.png') no-repeat;
    background-size: 100% 0.53rem;
    .content {
      width: 10rem;
      margin: 0 auto;
      padding-top: 0.9rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .content-header {
      img {
        position: relative;
        left: -0.5rem;
        width: 5.32rem;
        height: 5.27rem;
      }
    }
    .content-mid {
      .dfox-view {
        position: relative;
        width: 4.8rem;
        height: 5.79rem;
        background: url('../assets/images/main/dfox-view.png') no-repeat;
        background-size: contain;
      }
    }
    .content-footer {
      width: 10rem;
      height: 3.48rem;
      margin: 1rem auto;
      background: url('../assets/images/main/Drowser.png') no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding-top: 0.3rem;
      .title {
        width: 6.4rem;
        height: 0.62rem;
        line-height: 0.62rem;
        border: 0.01rem solid rgb(215, 215, 215, 0.1);
        border-radius: 0.4rem;
        font-size: 0.32rem;
        font-family: HelveticaNeue, HelveticaNeue-Regular;
        font-weight: 400;
        text-align: center;
        color: #333333;
      }
      .dfox-button-wrap {
        display: flex;
        .btn-box-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 2.24rem;
          height: 1.31rem;
          &:nth-of-type(n + 2) {
            margin-left: 0.3rem;
          }
          .btn-box-wrap {
            height: 0.94rem;
            .btn-box {
              margin-top: 0.06rem;
              width: 2.24rem;
              height: 0.88rem;
              border-radius: 0.15rem;
              background-color: #fff;
              border: 0.02rem solid #fff;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              cursor: pointer;
              &.active {
                pointer-events: none;
              }
              img {
                width: 0.54rem;
                height: 0.54rem;
              }
              .text-wrap {
                display: flex;
                flex-direction: column;
                span {
                  font-size: 0.16rem;
                  font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400;
                  // color: #5638c3;
                  color: #333;
                }
                .bold-text {
                  font-size: 0.26rem;
                  font-family: PingFangSC, PingFangSC-Medium;
                  font-weight: 500;
                }
                .grey {
                  color: #a8a1b2;
                }
              }
            }
            .btn-box:hover {
              margin-top: 0;
              border: 0.02rem solid #8ce7fd;
              box-shadow: 0.06rem 0.03rem 0.15rem 0 rgba(0, 0, 0, 0.1);
            }
          }
          .tutorial-box {
            margin-top: 0.2rem;
            display: none;

            span {
              font-size: 0.12rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              color: #999;
            }
            .arrow {
              margin-left: 0.05rem;
              width: 0.06rem;
              height: 0.1rem;
              background: url('../assets/images/header/arrow-black.png')
                no-repeat;
              background-size: 100% 100%;
            }
          }
          .tutorial-box:hover {
            span {
              color: #0debff;
            }
            .arrow {
              background: url('../assets/images/header/arrow-blue.png')
                no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        .btn-box-content:hover {
          .tutorial-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media screen and(max-width:767px) {
  .main-content {
    .content-header {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 4.49rem;
        height: 4.6rem;
      }
    }
    .content-mid {
      display: flex;
      flex-direction: column;
      align-items: center;
      .dfox-view {
        position: relative;
        width: 4.8rem;
        margin: 0 auto;
        margin-top: 1rem;
        height: 5.79rem;
        background: url('../assets/images/main/dfox-view.png') no-repeat;
        background-size: contain;
        background-position: -0.6rem;
      }
    }
    .content-footer {
      width: 96vw;
      // height: 7rem;
      margin: 1rem auto;
      background: url('../assets/images/main/Drowser.png') no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .title {
        width: 5.5rem;
        margin: 00.5rem 0;
        font-size: 0.54rem;
        font-family: HelveticaNeue, HelveticaNeue-Regular;
        font-weight: 700;
        text-align: center;
        color: #333333;
      }
      .dfox-button-wrap {
        display: flex;
        flex-direction: column;
        .btn-box-content {
          width: 4.3rem;
          height: 1rem;
          &:nth-of-type(n + 2) {
            margin-top: 0.3rem;
          }
          .btn-box {
            width: 4.3rem;
            height: 1rem;
            border-radius: 0.54rem;
            background-color: #fff;
            border: 0.02rem solid #d7d7d7;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.active {
              border: 0.02rem solid #a8a1b2;
              pointer-events: none;
            }
            img {
              width: 0.7rem;
              height: 0.7rem;
            }
            .text-wrap {
              margin-left: 0.3rem;
              display: flex;
              flex-direction: column;
              span {
                font-size: 0.2rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                // color: #7051e1;
                color: #333;
              }
              .bold-text {
                font-size: 0.3rem;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
              }
              .grey {
                color: #a8a1b2;
              }
            }
          }
        }
        .tutorial-box {
          display: none;
        }
      }
    }
  }
}
</style>
