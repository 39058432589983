<template>
  <div class="home">
    <!-- <div class="warn">
      <img src="../assets/warn.png" />
      <a href="https://tokenpocket-gm.medium.com/69754fbe0728"> -->
        <!-- <span>
          dfox Extension Announced Rebranding, Brand Name Officially Upgraded to
          TokenPocket Extension
        </span> -->
        <!-- <span>
          {{messages.upgradeWarn}}
        </span>
      </a>
    </div> -->
    <!-- pc端头部 -->
    <HeaderNavBar :messages="messages" class="header-nav-bar" />
    <!-- 移动端头部 -->
    <HeaderNavBarMini :messages="messages" class="header-nav-bar-mini" />
    <MainContent :messages="messages" />
    <FooterBottom :messages="messages" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FooterBottom from '../components/FooterBottom.vue'
import HeaderNavBar from '../components/HeaderNavBar.vue'
import HeaderNavBarMini from '../components/HeaderNavBarMini.vue'
import MainContent from '../components/MainContent.vue'

import english from '../lang/en'
import chinese from '../lang/zh'
import chineseTC from '../lang/tc'

export default {
  name: 'Home',
  components: {
    HeaderNavBar,
    HeaderNavBarMini,
    MainContent,
    FooterBottom,
  },
  data() {
    return {
      english: english,
      chinese: chinese,
      chineseTC: chineseTC,
      messages: {},
    }
  },
  computed: {
    ...mapGetters(['language']),
  },
  watch: {
    language: {
      immediate: true,
      handler() {
        if (this.language === 'zh') {
          this.messages = this.chinese
        } else if (this.language === 'en') {
          this.messages = this.english
        } else {
          this.messages = this.chineseTC
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.home {
  .warn {
    height: 0.68rem;
    background: #1dcc91;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 0.1rem;
      width: 0.2rem;
      height: 0.2rem;
    }
    a {
      display: flex;
      span {
        font-size: 0.16rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
// pc
@media screen and(min-width:767px) {
  .header-nav-bar {
    display: block;
  }
  .header-nav-bar-mini {
    display: none;
  }
}
// 移动
@media screen and(max-width:767px) {
  .home {
    .warn {
      height: 1.3rem;
      padding: 0 30px;
      img {
        width: 0.35rem;
        height: 0.35rem;
        margin-right: 0.2rem;
      }
      a {
        span {
          font-size: 0.26rem;
          text-align: left;
        }
      }
    }
  }
  .header-nav-bar {
    display: none;
  }
  .header-nav-bar-mini {
    display: block;
  }
}
</style>
