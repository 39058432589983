import Vue from 'vue'
import Vuex from 'vuex'
import _ from "lodash";

Vue.use(Vuex)

const availableLocale = ['zh', 'en', 'zh-tw'];

let defaultLangStr = navigator.language
let defaultLang = 'en'

if (defaultLangStr.indexOf('zh') >= 0) {
  defaultLang = 'zh'
}

const query = location.search.substr(1);
let queryObj = {};

if (query) {
    _.forEach(query.split('&'), item => {
        const tempArr = item.split('=');
        queryObj[tempArr[0]] = tempArr[1];
    });
}

let locale = _.includes(availableLocale, queryObj['locale']) ? queryObj['locale'] : defaultLang;

// locale = localStorage.getItem('locale') || locale;

export default new Vuex.Store({
  state: {
    language: locale,
  },
  mutations: {
    changeLanguage(state, lang) {
      state.language = lang
    },
  },
  getters: {
    language: (state) => state.language,
  },
  actions: {},
  modules: {},
})
