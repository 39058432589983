const iconGrey = require('../../assets/images/main/chrome-grey.png')
const iconDesktop = require('../../assets/images/header/desktop.png')
const installTutorial = '插件安裝教程'
const installTutorialUrl =
  'https://help.tokenpocket.pro/cn/extension-wallet/faq/installation-tutorial'

export default {
  language: '简体',
  extension: '插件钱包',
  download: '立即下载',
  dfoxTitle: '区块链入口探索 Web3 世界',
  dfoxDesc: '区块链入口，探索去中心化应用',
  downloadFor: '立即下载',
  upgradeWarn: 'dfox 插件钱包宣布更名，品牌名称正式升级为 TokenPocket 插件钱包',
  installTutorial: installTutorial,
  installTutorialUrl: installTutorialUrl,
  supportText: '现已支持Chrome、Brave浏览器（Firefox及Opera正在支持中)',
  mobileDropDownList: [
    {
      title: installTutorial,
      url: installTutorialUrl,
    },
    {
      title: '语言',
      lang: true,
      children: [
        { langText: '简体', lang: 'zh' },
        { langText: '繁體', lang: 'tc' },
        { langText: 'English', lang: 'en' },
      ],
    },
  ],
  btnList: [
    {
      icon: require('../../assets/images/header/chrome.png'),
      iconGrey: iconGrey,
      btnTextTop: '下载',
      btnText: 'Chrome',
      installTutorial: installTutorial,
      installTutorialUrl: installTutorialUrl,
      url: 'https://chrome.google.com/webstore/detail/dfox/mfgccjchihfkkindfppnaooecgfneiii?hl=zh-CN',
    },
    {
      icon: iconDesktop,
      iconGrey: iconDesktop,
      btnTextTop: 'Download to',
      btnText: '本地下载',
      installTutorial: installTutorial,
      installTutorialUrl: installTutorialUrl,
      url: 'https://extension.tokenpocket.pro/tp-chrome.zip',
    },
  ],
  dfoxImg: require('../../assets/images/header/dfox-ch.png'),
  dappsDefi: {
    title: 'DApp生态入口',
    desc: '探索DeFi,GameFi等,快速玩转DApp经济',
  },
  multiChain: {
    title: '多链钱包，简单易用',
    desc: '一个钱包，管理多链资产',
  },
  installDofx: '添加TokenPocket插件至浏览器',
  joinDfox: '加入TokenPocket社区',
  linkList: [
    {
      text: '隐私政策',
      url: 'https://help.tokenpocket.pro/cn/extension-wallet/privacy-policy',
    },
    {
      text: '服务协议',
      url: 'https://help.tokenpocket.pro/cn/extension-wallet/term-of-use',
    },
  ],
}
