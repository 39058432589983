<template>
  <div class="header-nav-bar-mini">
    <!-- 头部logo -->
    <div class="logo-title">
      <div class="left">
        <img src="../assets/images/header/logo.png" />
        <span>{{ messages.extension }}</span>
      </div>
      <span class="down-line" ref="languageBtn" @click="showLanguageList">
        <!-- {{ messages.language }} -->
      </span>
      <div class="lang-box" v-show="isShowLanguageBox">
        <div
          class="lang-text-box"
          v-for="(navItem, index) in messages.mobileDropDownList"
          :key="index"
          @click="onNavItem(navItem)"
        >
          <div>{{ navItem.title }}</div>
          <img
            class="arrow-down"
            :class="{ active: isShowLanguageListBox }"
            v-if="navItem.lang"
            src="../assets/images/header/arrow-down.png"
          />
          <div
            class="lang-list-box"
            v-if="isShowLanguageListBox"
            @click="onChangeLang"
          >
            <div
              class="lang-text-box lang-list"
              v-for="(langItem, index) in navItem.children"
              :key="index"
              :data-lang="langItem.lang"
            >
              {{ langItem.langText }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="title-1" v-if="language !== 'en'">
      {{ messages.dfoxTitle.slice(0, 5) }} <br />
      {{ messages.dfoxTitle.slice(5) }}
    </span>
    <span class="title-1" v-else>
      {{ messages.dfoxTitle }}
    </span>
    <!-- <div class="title-2">
      {{ messages.dfoxDesc }}
    </div> -->
    <div class="zh-placeholder" v-if="language !== 'en'"></div>

    <div class="download-for">{{ messages.downloadFor }}</div>
    <!-- 下载按钮区域 -->
    <div class="btn-tutorial">
      <div
        class="btn-tutorial-wrap"
        v-for="(item, index) in messages.btnList"
        :key="index"
      >
        <div
          class="btn-box"
          :class="{ active: index === grayIndex }"
          @click="openUrl(item.url, index)"
        >
          <img
            v-if="index === grayIndex"
            src="../assets/images/header/chrome-grey.png"
          />
          <img v-else :src="item.icon" />
          <div class="text-wrap">
            <span
              v-if="
                (index !== 1 && (language === 'zh' || language === 'tc')) ||
                language === 'en'
              "
              :class="{ grey: index === grayIndex }"
            >
              {{ item.btnTextTop }}
            </span>
            <span class="bold-text" :class="{ grey: index === grayIndex }">{{
              item.btnText
            }}</span>
          </div>
        </div>
        <!-- <div
          class="tutorial-box"
          v-if="index === 1"
          @click="openUrl(item.installTutorialUrl)"
        >
          <span>{{ item.installTutorial }}</span>
          <img src="../assets/images/header/arrow-mobile.png" />
        </div> -->
      </div>
    </div>
    <div class="title-2 support-text">
      {{ messages.supportText }}
    </div>
    <!-- 底部图片 -->
    <div class="img-content">
      <img :src="messages.dfoxImg" />
      <img src="../assets/images/header/dfox-glass-1.png" />
      <img src="../assets/images/header/dfox-glass-2.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      languageList: [
        { title: 'install tutorial' },
        {
          title: '语言',
          children: [
            { langText: '简体', lang: 'zh' },
            { langText: '繁體', lang: 'tc' },
            { langText: 'English', lang: 'en' },
          ],
        },
      ],
      isShowLanguageBox: false,
      isShowLanguageListBox: false,
      grayIndex: 10, // 需要第几个按钮置灰，如果超出则全部不置灰
    }
  },
  mounted() {
    // 监听，除了点击自己，点击其他地方将自身隐藏
    document.addEventListener('click', this.onOutside)
  },
  computed: {
    ...mapGetters(['language']),
  },
  methods: {
    showLanguageList() {
      this.isShowLanguageBox = !this.isShowLanguageBox
    },
    onOutside(e) {
      const languageBtn = this.$refs.languageBtn
      if (!languageBtn.contains(e.target)) {
        const contentWrap = document.querySelector('.lang-box')
        if (contentWrap) {
          if (!contentWrap.contains(e.target)) {
            this.isShowLanguageBox = false
            this.isShowLanguageListBox = false
          }
        }
      }
    },
    onNavItem(navItem) {
      if (navItem.url) {
        window.open(navItem.url)
      } else if (navItem.children) {
        this.isShowLanguageListBox = !this.isShowLanguageListBox
      }
    },
    onChangeLang(e) {
      this.$store.commit('changeLanguage', e.target.dataset.lang)
    },
    openUrl(url, index) {
      console.log(url)
      if (index === 1) {
        window.open(url, '_self')
      } else {
        window.open(url)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and(max-width:767px) {
  .header-nav-bar-mini {
    position: relative;
    width: 100vw;
    height: 16.24rem;
    background: url('../assets/images/header/bg-mobile.png') no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo-title {
      width: 88vw;
      height: 2rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 2.628rem;
          height: 0.384rem;
          margin-right: 0.3rem;
        }
        // span {
        //   margin-top: 0.1rem;
        // }
      }
      span {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #ffffff;
      }
      .down-line {
        width: 0.36rem;
        height: 0.36rem;
        background: url('../assets/images/header/down-line.png') no-repeat;
        background-size: contain;
      }
      .lang-box {
        position: absolute;
        z-index: 1110;
        top: 1.7rem;
        left: 0;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .lang-text-box {
          width: 95vw;
          margin: 0 auto;
          color: #333;
          font-size: 0.3rem;
          height: 1rem;
          line-height: 1rem;
          border-bottom: 0.02rem solid #f7f5ff;
          display: flex;
          justify-content: center;
          align-items: center;
          .arrow-down {
            transform-origin: center center;
            transform: rotate(-90deg);
            width: 0.3rem;
            height: 0.3rem;
            &.active {
              transform: rotate(0);
            }
          }
          .lang-list-box {
            width: 100vw;
            position: absolute;
            background: #f7f5ff;
            top: 2rem;
            .lang-list {
              border-bottom: 0.02rem solid #fff;
            }
          }
        }
      }
    }
    .title-1 {
      display: flex;
      justify-content: center;
      width: 5rem;
      margin: 0 auto;
      font-size: 0.66rem;
      font-family: HelveticaNeue, HelveticaNeue-Regular;
      font-weight: 700;
      text-align: center;
      color: #ffffff;
    }
    .title-2 {
      width: 5rem;
      margin: 0 auto;
      margin-top: 0.3rem;
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
    }
    .zh-placeholder {
      width: 0.4rem;
      margin: 0 auto;
      margin-top: 0.6rem;
      height: 0.05rem;
      background: #ffffff;
      border-radius: 0.05rem;
    }
    .download-for {
      width: 5rem;
      margin: 0 auto;
      margin-top: 0.6rem;
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
    .btn-tutorial {
      width: 5.5rem;
      margin: 0 auto;
      margin-top: 0.3rem;
      display: flex;
      .btn-tutorial-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        // 选择第二个元素之后的所有元素
        &:nth-of-type(n + 2) {
          margin-left: 0.2rem;
        }
        .btn-box {
          width: 2.62rem;
          height: 1rem;
          background: url('../assets/images/header/btn-bg-hover.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active {
            pointer-events: none;
            background: url('../assets/images/header/btn-bg-grey.png') no-repeat;
            background-size: 100% 100%;
          }
          img {
            margin-top: 0.04rem;
            width: 0.54rem;
            height: 0.54rem;
          }
          .text-wrap {
            margin-top: 0.04rem;
            margin-left: 0.15rem;
            display: flex;
            flex-direction: column;
            span {
              font-size: 0.2rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #ffffff;
            }
            .bold-text {
              font-size: 0.3rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
            }
            .grey {
              color: #463069;
            }
          }
        }
        .tutorial-box {
          margin-top: 0.2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 0.22rem;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: #ffffff;
          }
          img {
            margin-left: 0.08rem;
            // width: 0.135rem;
            // height: 0.216rem;
            width: 0.11rem;
            height: 0.176rem;
          }
        }
      }
    }
    .support-text {
      width: 5.5rem;
      opacity: 0.85;
      font-size: 0.2rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
    .img-content {
      width: 5.6rem;
      height: 7rem;
      margin: 0 auto;
      margin-top: 0.7rem;
      position: relative;
      @keyframes move {
        0% {
          top: 0;
        }
        20% {
          top: -0.7rem;
        }
        40% {
          top: 0;
        }
        60% {
          top: -0.6rem;
        }
        100% {
          top: 0;
        }
      }
      :nth-child(1) {
        animation: move 0.8s ease-in-out 1.6s;
        width: 3.58rem;
        height: 6.45rem;
        position: absolute;
        z-index: 1001;
      }
      :nth-child(2) {
        animation: move 0.8s ease-in-out 1.4s;
        width: 2.52rem;
        height: 5.48rem;
        position: absolute;
        z-index: 1000;
        left: 2rem;
      }
      :nth-child(3) {
        animation: move 0.8s ease-in-out 1.2s;
        width: 1.96rem;
        height: 4.27rem;
        position: absolute;
        z-index: 999;
        left: 4rem;
      }
    }
  }
}
</style>
