const iconGrey = require('../../assets/images/main/chrome-grey.png')
const iconDesktop = require('../../assets/images/header/desktop.png')
const installTutorial = 'Install Tutorial'
const installTutorialUrl =
  'https://help.tokenpocket.pro/en/extension-wallet/faq/installation-tutorial'

export default {
  language: 'English',
  extension: 'Extension',
  download: 'Download Now',
  dfoxTitle: 'A Crypto Wallet A Gateway to Web3',
  dfoxDesc: 'Start exploring blockahain applications in seconds.',
  downloadFor: 'Download For',
  upgradeWarn:
    'dfox Extension Announced Rebranding, Brand Name Officially Upgraded to TokenPocket Extension',
  installTutorial: installTutorial,
  installTutorialUrl: installTutorialUrl,
  supportText:
    'Chrome and Brave browsers are now supported (Firefox and Opera are currently being supported)',
  mobileDropDownList: [
    {
      title: installTutorial,
      url: installTutorialUrl,
    },
    {
      title: 'Language',
      lang: true,
      children: [
        { langText: '简体', lang: 'zh' },
        { langText: '繁體', lang: 'tc' },
        { langText: 'English', lang: 'en' },
      ],
    },
  ],
  btnList: [
    {
      icon: require('../../assets/images/header/chrome.png'),
      iconGrey: iconGrey,
      btnTextTop: 'Get it on',
      btnText: 'Chrome',
      installTutorial: installTutorial,
      installTutorialUrl: installTutorialUrl,
      url: 'https://chrome.google.com/webstore/detail/dfox/mfgccjchihfkkindfppnaooecgfneiii?hl=EN',
    },
    {
      icon: iconDesktop,
      iconGrey: iconDesktop,
      btnTextTop: 'Download to',
      btnText: 'Desktop',
      installTutorial: installTutorial,
      installTutorialUrl: installTutorialUrl,
      url: 'https://extension.tokenpocket.pro/tp-chrome.zip',
    },
  ],
  dfoxImg: require('../../assets/images/header/dfox-en.png'),
  dappsDefi: {
    title: 'Support all kinds of DApps & DeFi',
    desc: 'Connect to DeFi&GameFi, explore conveniently',
  },
  multiChain: {
    title: 'Smooth Multi-chain experience',
    desc: 'One wallet, easy to manage multi-chain assets',
  },
  installDofx: 'Install TokenPocket for your browser',
  joinDfox: 'Join the TokenPocket Community',
  linkList: [
    {
      text: 'Privacy Policy',
      url: 'https://help.tokenpocket.pro/en/extension-wallet/privacy-policy',
    },
    {
      text: 'Terms of Service',
      url: 'https://help.tokenpocket.pro/en/extension-wallet/term-of-use',
    },
  ],
}
